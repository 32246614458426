body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  -moz-transform: scale(1.3); /* for Firefox, default 1*/
  zoom:85%; /* For Chrome, IE, default 100%*/
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.foot{
  display: block;
  position: absolute;
  bottom: 0;
  background: linear-gradient(90deg,rgba(40, 47, 100, 0.8),rgba(120,2,6,.8));
  width: 100%;
  padding: 2%;
  color: #fff;
}

html, body{
  padding: 0;
  margin: 0;
}
html{
  height: 100%;
}
body{
  min-height: 100%;
}