.headBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: space-between;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: white;
  /* margin: 2%; */
  width: 100%;
  border-radius: 10rem;
  background: linear-gradient(90deg,rgba(6,17,97,.8),rgba(120,2,6,.8));
}

.titulo {
  padding-left: 2%;
}

.perfil {
  align-self: 30px;
  border-radius: 50%;
  margin: 20px;
}

.boton{
  position: relative;
  
  transform: translateZ(10%);

}

h1 {
  animation: color-change 1s infinite;
}

h3 {
  animation: color-change-b 1s infinite;
}

@keyframes color-change {
  0% { color: rgb(178, 194, 37); }
  50% { color: rgb(179, 54, 96); }
  100% { color: rgb(187, 17, 221); }
}

@keyframes color-change-b {
  0% { color: rgb(190, 176, 50); }
  50% { color: rgb(206, 119, 38); }
  100% { color: rgb(112, 31, 187); }
}

.header{
  margin-bottom: 2%;
  margin-top: 1%;
}

.bot{
  margin-top: 10px;
}