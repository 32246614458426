@mixin on-circle($item-count, $circle-size, $item-size) {
    position: relative;
    width:  $circle-size;
    height: $circle-size;
    padding: 0;
    border-radius: 50%; 
    list-style: none;       
    
    > * {
      display: block;
      position: absolute;
      top:  50%; 
      left: 50%;
      width:  $item-size;
      height: $item-size;
      margin: -($item-size / 2);
    
      $angle: (360 / $item-count);
      $rot: 0;
  
      @for $i from 1 through $item-count {
        &:nth-of-type(#{$i}) {
          transform: 
            rotate($rot * 1deg) 
            translate($circle-size / 2) 
            rotate($rot * -1deg);
        }
  
        $rot: $rot + $angle;
      }
    }
  }

  .circle-container {
    @include on-circle($item-count: 8, $circle-size: 20em, $item-size: 6em); 
    margin: 5em auto 5em;
    
    img { 
      display: block; 
      max-width: 100%; 
      border-radius: 50%;
      border: solid 1px orange;
      transition: .15s;
      
      &:hover,
      &:active {
        filter: grayscale(0);
      }
    }
  }

  .logos {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: space-between;
    text-align: center;
    padding: 8%;
    padding-top: 2%;
    padding-bottom: 2%;
    width: 100%;
    border-radius: 10rem;
    background: linear-gradient(90deg,rgba(6,17,97,.8),rgba(120,2,6,.8));;
}

.logoDoble{
  display:flex;
  justify-content: center;
  width: 30%;
}

.logos1{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.logos2{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

}

.logos3{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

}
.logos4{
  display: flex;
  flex-direction: column;
  justify-content: space-between;

}

@media (max-width: 950px) {

  .circle-container{
    display:none;
  }

  .logos1b, .logos2b, .logos3b, .logos4b{
    display:flex;
    flex-direction: column;
  }

  .logos1{
    justify-content: space-between;
  }
  .logos1b{
    justify-content: space-evenly;
  }
  
  .logos2{
    justify-content: space-around;
  }
  .logos2b{
    justify-content: space-evenly;
  }

  .logos3{
    justify-content: space-around;
  }

  .logos3b{
    justify-content: space-evenly;
  }

  .logos4b{
    justify-content: space-evenly;
  }

  .logosBody, .logos{
    height: 450px;
  }

  .logoPhone, .perfil{
    width: 80px;
    height: 80px;
  }

  h1, h3 {
    font-size: medium;
  }

  .foot{
    display: block;
    position: absolute;
    text-align: center;
  }
}

@media (max-width: 675px) {
  .logoPhone, .perfil{
    width: 60px;
    height: 60px;
  }
  .logos1{
    justify-content: space-evenly;
  }
  .logos2{
    justify-content: space-evenly;
  }
  
  .logos3{
    justify-content: space-evenly;
  }
  .logos4{
    justify-content: space-evenly;
  }
}

@media (max-width: 507px) {
  .logoPhone, .perfil{
    width: 50px;
    height: 50px;
  }
}

@media (max-width: 450px) {
  .logos{
    justify-content: center;
  }
  .logoPhone, .perfil{
    width: 30px;
    height: 30px;
  }
}





div {
    div{
        ul{
            li{
                p{
                    display: block;
                    color:white;
                    padding-right: 17px;
                    font-weight: bold;
                }    
            }
        }
    }
}

.logoDoble{

  display:flex;
  flex-direction: column;
  justify-content: space-between;

}