.about {
    width: 100%;
    display: flex;
    text-align: center;
    justify-content: center;
    justify-items: center;
    color: white;
  }

.box {
  margin: 5%;
  width: 60%;
  background: linear-gradient(90deg,rgba(6,17,97,.8),rgba(120,2,6,.8));
  box-shadow: -34px 23px 15px -1px rgba(10,5,29,.88);
  border-radius: 3rem;
}

@media (max-width: 750px){
    p{
      font-size: small;
    }
    .box{
      width: 80%;
    }
}

@media (max-width: 450px){ 
  p{
    font-size: xx-small;
  }
  .box{
    width: 80%;
  }
}

