.contactContainer {
    width: 100%;
    display: flex;
    text-align: center;
    justify-content: center;
    justify-items: center;
    color: white;
    font-size: calc(10px + 2vmin);
  }

.contactBox {
    margin: 5%;
    width: 50%;
    box-shadow: -34px 23px 15px -1px rgba(10,5,29,.88);
    margin: 5%;
    width: 60%;
    background: linear-gradient(90deg,rgba(6,17,97,.8),rgba(120,2,6,.8));
    border-radius: 3rem;
}

.contact {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.contactP {
    margin-top: 0;
    margin-bottom: 1rem;
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

a {
    color: white;
}

img {
    vertical-align: middle;
    border-style: none;
}

@media (max-width: 750px){
    .contactP{
        font-size: medium;
    }
    .contact{
        flex-direction: column;
    }
    imgContact{
        height: 10px;
        width: 10px;
    }
  }

  @media (max-width: 450px){
    .contactP{
        font-size: xx-small;
    }
    .contact{
        flex-direction: column;
    }
    .imgContact{
        height: 10px;
        width: 10px;
    }
  }

 

