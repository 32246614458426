.logos{
    display:flex;
    justify-content: space-between;
    align-items: space-evenly;
    box-sizing:border-box;

    .circle-container{
        width: 220px;
        height: 220px;
        border-radius: 50%;
        padding: 10px;
        -webkit-animation:spin 7s linear infinite;
        -moz-animation:spin 7s linear infinite;
        animation:spin 7s linear infinite;
    }
}

@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }